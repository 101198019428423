import { Button, ButtonProps } from "@mui/material"
import React, { PropsWithChildren } from "react"

type CommonButtonProps = PropsWithChildren<ButtonProps>

export const CommonButton: React.FunctionComponent<CommonButtonProps> = ({
  children,
  sx = {},
  ...props
}) => {
  return (
    <Button
      {...props}
      color={props.color || "primary"}
      className={`relative inline-flex items-center justify-center whitespace-nowrap ${
        props.className || ""
      }`}
      sx={{
        fontFamily: "Noto Sans JP, sans-serif",
        minWidth: 96,
        minHeight: 60,
        fontSize: 18,
        textTransform: "capitalize",
        borderRadius: 2,
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

import { Alert, Snackbar } from "@mui/material"
import React from "react"
import AlertContainer from "../../core/store/AlertContainer"

type AlertMessageProps = {}

export const AlertMessage: React.FunctionComponent<AlertMessageProps> = () => {
  const {
    state: { display, severity, message },
  } = AlertContainer.useContainer()

  return (
    <Snackbar
      open={display}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={severity} elevation={6}>
        <span className="whitespace-pre-line">{message}</span>
      </Alert>
    </Snackbar>
  )
}

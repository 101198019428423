import { createTheme } from "@mui/material"

export const materialTheme = createTheme({
  typography: {
    fontFamily: "Noto Sans TC, sans-serif",
  },
  spacing: 4,
  palette: {
    primary: {
      light: "#D6E8FF",
      200: "#2CA4DE",
      300: "#228FCE",
      400: "#1E7DBE",
      main: "#1A68AE",
      600: "#18539E",
      contrastText: "#fff",
    },
    error: {
      main: "#FF675E",
      dark: "#BF2C21",
      contrastText: "#fff",
    },
    text: {
      primary: "#333333",
      secondary: "#757575",
    },
    action: {
      normal: "#dcdddf",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
      formControlBg: "#f5f8fa",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
})

import React, { PropsWithChildren, useEffect } from "react"
import "../../plugins/i18next"
import { materialTheme } from "@/theme/materialTheme"
import { ThemeProvider } from "@mui/material"
import AlertContainer from "../../core/store/AlertContainer"
import { AlertMessage } from "../common/AlertMessage"
import { AlertModal } from "../common/AlertModal"
import { diverChain } from "@/core/constant/chain"
import { publicProvider } from "@wagmi/core/providers/public"
import { configureChains, createClient, mainnet, WagmiConfig } from "wagmi"
import { InjectedConnector } from "wagmi/connectors/injected"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { bsc, localhost, polygon } from "@wagmi/chains"
import { useSearchParams } from "react-router-dom"

export const Layout: React.FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [searchParams] = useSearchParams()
  const isMobileDiverWallet = searchParams.get("is_diver_wallet") === "true"
  const testModel = sessionStorage.getItem("metamask_connect")
  const { chains, provider, webSocketProvider } = configureChains(
    [diverChain, mainnet, polygon, localhost, bsc],
    [publicProvider()]
  )

  useEffect(() => {
    if (isMobileDiverWallet) {
      window.ethereum.isDiverWallet = true
      localStorage.setItem("is_diver_wallet", "true")
    }
  }, [])

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: () => {
      const connectors = [
        new InjectedConnector({
          chains,
          options: {
            name: "DiverWallet",
            getProvider: () =>
              window.ethereum?.isDiverWallet || isMobileDiverWallet
                ? window.ethereum
                : undefined,
            shimDisconnect: true,
          },
        }),
      ]
      if (testModel === "true") {
        connectors.push(
          new MetaMaskConnector({
            chains,
            options: {
              shimDisconnect: true,
            },
          })
        )
      }
      return connectors
    },
    provider,
    webSocketProvider,
  })

  return (
    <ThemeProvider theme={materialTheme}>
      <WagmiConfig client={wagmiClient}>
        <AlertContainer.Provider>
          <main className="h-available flex flex-col">{children}</main>
          <AlertMessage />
          <AlertModal />
        </AlertContainer.Provider>
      </WagmiConfig>
    </ThemeProvider>
  )
}

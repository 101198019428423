import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { enKeyCode } from "@/core/constant/enTranslate"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    // TODO fixed language in en by business model in ticket https://app.asana.com/0/1204807419154141/1205850268211660/f
    // lng:
    //   (typeof window !== "undefined" &&
    //     (localStorage.getItem("i18nextLng") ||
    //       getBrowserLang(navigator.language))) ||
    //   "en",
    debug: false,
    resources: {
      // ja: {
      //   translation: jaKeyCode,
      // },
      en: {
        translation: enKeyCode,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch()

export default i18next

import { TranslateType } from "@/types/TranslateType"

export const enKeyCode: TranslateType = {
  str_about_service: "About Oceans Rare",
  str_access_service: "Accessing and Using The Service",
  str_account: "Account",
  str_amount: "Amount",
  str_amount_placeholder: "Please enter amount",
  str_back_to_my_nft: "Back to My NFTs",
  str_balance: "Balance",
  str_balance_money: "Balance: {{money}} DIV",
  str_banner_image: "Banner Image",
  str_banner_image_hint:
    "This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices.",
  str_blockchain: "Blockchain",
  str_blockchain_placeholder: "Please select blockchain",
  str_burn: "Burn",
  str_burn_btn: "I understand and Burn this NFT",
  str_burn_content:
    "Burning a non-fungible token means destroying it. Burned NFTs are\n sent to a verifiable un-spendable address, ultimately eliminating\n your NFT from the blockchain.",
  str_burn_hint: " * The action can't restore after submit",
  str_burn_success: "Burn Application Successfully",
  str_burn_success_message:
    "When Transaction finish, the NFT will be remove from your own NFT",
  str_buy: "Buy",
  str_buy_nft: "Buy NFT",
  str_buying_price: "Price",
  str_cancel: "Cancel",
  str_category: "Category",
  str_category_placeholder: "Please select category",
  str_change_price: "Change Price",
  str_change_to_this_privacy_policy: "Changes to this Privacy Policy",
  str_clear_all: "Clear All",
  str_collection: "Collections",
  str_collection_description: "Collection Description",
  str_collection_description_placeholder:
    "Provide a detailed description of your collection",
  str_collection_list: "Collection List",
  str_collection_name: "Collection Name",
  str_collection_name_placeholder: "Please enter collection name",
  str_collection_of_information: "Collection of Information",
  str_collection_other_nft: "Other Collection NFTs",
  str_confirm: "Confirm",
  str_connect_diver_wallet: "Connect to DIVER Wallet",
  str_connect_wallet: "Connect Wallet",
  str_contact: "Contact",
  str_contact_us: "Contact Us",
  str_contract_address: "Contract Address",
  str_cookie: "Cookie",
  str_create_collection: "Create Collection",
  str_create_collection_btn: "Create Collection",
  str_create_collection_step1_message: "Uploading...",
  str_create_collection_step2_message: "Creating...",
  str_create_collection_success: "Create Collection Successfully",
  str_create_collection_success_message:
    "The collection is created and you can create NFT directly",
  str_create_nft: "Create NFT",
  str_date: "Date",
  str_detail: "Detail",
  str_disconnect_wallet: "Disconnect Wallet",
  str_diver_market_balance: "Oceans Rare Balance",
  str_done: "Done",
  str_english: "English",
  str_error_message_name_limit: "Please enter less than 1,000 characters",
  str_exhibiting_nft: "Listed NFTs",
  str_ext_import: "ExtNFT import",
  str_external_link: "External Link",
  str_failed_buy: "Buy failed",
  str_favorite: "Favorites",
  str_feature_image: "Feature Image",
  str_feature_image_hint:
    "This image will be used for featuring your collection on the homepage, category pages.",
  str_file_size_exceed_10MB: "File size exceeds 10MB",
  str_file_upload_failed: "File upload failed",
  str_filter_by: "Filter",
  str_filter_by_all: "All",
  str_filter_by_sell: "For sale",
  str_filter_by_sold: "Not for sale",
  str_get_upload_link_failed: "Failed to get upload link",
  str_goods_name: "Name",
  str_goto_collection_list: "Go to Collection List",
  str_goto_collection_when_empty:
    "※ For those who haven't created a collection, <0>click here<0>",
  str_goto_create_nft: "Go to create NFT",
  str_goto_my_page: "Go to My Page",
  str_home: "Home",
  str_imitation_of_liability: "Limitation of liability",
  str_install_diver_wallet_extension: "Install Diver Wallet extension",
  str_insufficient_balance: "Insufficient balance",
  str_intellectual_property: "Intellectual Property",
  str_item_count: "Item: {{count}}",
  str_japanese: "日本語",
  str_law_jurisdiction: "Applicable Law and Jurisdiction",
  str_list_nft: "List NFT",
  str_list_nft_failed: "List NFT failed",
  str_logo_image: "Logo Image",
  str_logo_image_hint: "This image will also be used for navigation.",
  str_main_title_primary: "Past, Present, Future",
  str_main_title_secondary: "Weaving Time",
  str_main_title_secondary_2: " in the NFT Marketplace",
  str_main_title_third: "Oceans Rare",
  str_max: "Max",
  str_meta_title: "Oceans Rare",
  str_mint: "Mint",
  str_mint_failed: "Create failed",
  str_mint_nft_btn: "Mint NFT",
  str_mint_success: "Created Successfully",
  str_mint_success_message:
    "When Transaction finish, the NFT will be display in account",
  str_missing_form_data: "Missing form data",
  str_modifications: "Modifications",
  str_more: "See More",
  str_my_nft: "My NFTs",
  str_network_error_confirmBtn: "I understand",
  str_network_error_message:
    "The network is incorrect, please switch to the right network. ({{blockchain}})",
  str_network_error_title: "Error Network!",
  str_nft_burn: "NFT Burn",
  str_nft_collection: "Collection",
  str_nft_collection_placeholder: "Please select collection",
  str_nft_cover: "NFT Cover",
  str_nft_cover_hint:
    "File types supported: JPG, JPEG, PNG, GIF. Max size: 10 MB",
  str_nft_description: "NFT Description",
  str_nft_description_placeholder:
    "Provide a detailed description of your goods",
  str_nft_list: "List of NFTs",
  str_nft_name: "NFT Name",
  str_nft_name_placeholder: "Please enter NFT name",
  str_nft_photo: "NFT Image, Video, Audio",
  str_nft_photo_hint:
    "File types supported: JPG, JPEG, PNG, GIF, MP4, MP3. Max size: 10 MB",
  str_nft_price: "Price",
  str_nft_price_placeholder:
    "Price can only be set in multiples of 10, such as 10, 20, 30..., and the maximum limit is 10,000,000,000",
  str_nft_revoke_content:
    "You are going to delist the selected NFT. \n Please confirm to proceed to the next step.",
  str_nft_revoke_success: "Revoke successfully",
  str_nft_selling_enable: "Do you want to sell this NFT?",
  str_nft_supply: "Supply",
  str_nft_supply_placeholder: "The number of items that can be minted",
  str_nft_transfer: "NFT Transfer",
  str_offer: "Offer",
  str_order_by: "Sort By",
  str_order_by_new: "New",
  str_order_by_old: "Old",
  str_order_by_price_asc: "Price Asc",
  str_order_by_price_desc: "Highest Price",
  str_order_by_watch: "View Count",
  str_owner: "Owner",
  str_please_enter_a_valid_number: "Please enter a valid number",
  str_please_enter_ten_multiples: "Please enter a multiple of ten",
  str_please_fill_out_this_field: "Please fill out this field",
  str_please_select: "Please Select",
  str_please_switch_diver: "Please switch to Diver Chain",
  str_positive_integer: "Please enter a positive integer",
  str_price_history: "Price History",
  str_pricing_fee: "Pricing and Fees",
  str_privacy: "Privacy",
  str_privacy_policy: "Privacy Policy",
  str_privacy_policy_changes_to_this_privacy_policy_1:
    "Changes to this Privacy Notice will be made when required in response to changing legal, technical, or business developments. When we update our Privacy Notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Notice changes if and where this is required by applicable data protection laws.\n\nYou can see when this Privacy Notice was last updated by checking the last updated date displayed at the top of this Privacy Notice. The new Privacy Notice will apply to all current and past users of the website and will replace any prior notices that are inconsistent with it.",
  str_privacy_policy_collection_of_information_1:
    "When you use our Services, we automatically collect information from your devices. For example, we may collect:",
  str_privacy_policy_collection_of_information_2:
    "<1>Log Information</1>\nWe collect log information when you use our Services, including access times, pages viewed, IP address, and the web page that referred you to our website.",
  str_privacy_policy_collection_of_information_3:
    "<1>Device Information</1>\nWe collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, your web browser, and device identifiers.",
  str_privacy_policy_collection_of_information_4:
    "<1>Network Information</1>\nIf you use our Services, we may collect bandwidth upload and download speeds, and other statistics about your device and network connection.",
  str_privacy_policy_collection_of_information_5:
    "<1>Usage Information</1>\nIf you use our Services, we may collect metadata about your usage and the file shards that are distributed via the Services (including shard size, number of shards, and frequency of access), and we may record instances in which you have used your private key to authenticate communications.",
  str_privacy_policy_contact_1:
    "We welcome all questions, concerns, and feedback you might have about these Terms. If you have suggestions for us, please contact our customer success team.",
  str_privacy_policy_cookie_1:
    "<0>Oceans Rare</0> does not typically use cookies. It reserves the ability to use them to affect the availability and functionality of our Site. You can deny a website the possibility to store cookies or cache data. In order to deny cookies or the caching of data, the User needs to edit the User’s browser settings. Please note that the User may experience less availability and functionality when using the Services if you chooses to turn off cookies or data cache.",
  str_privacy_policy_general_1:
    "Thank you for using Oceans Rare. <0>Oceans Rare</0> provides a platform for purchasing, selling, transferring, managing, and browsing Digital Artwork (collectively, including the Site, the Service).",
  str_privacy_policy_general_2:
    "Our Privacy Policy is incorporated into and is subject to the <0>Oceans Rare</0> Terms of Service, so please read both documents carefully. Your use of the Services indicates your consent to this Privacy Policy and our Terms of Service. If you do not want to be bound by these agreements, you may not use our Services.",
  str_privacy_policy_general_3:
    "<0>Oceans Rare</0> respects your privacy and strives to be as transparent as possible about how we use your personal information to provide you with secure services.Oceans Rare's security architecture and proprietary encryption methods are designed to ensure that only users designed to ensure that only users have access to message content.. Ever. Please contact us if you have any questions at info@diver-rare.com",
  str_privacy_policy_security_of_data_1:
    "The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.\n\nWhile our values will not shift, the platform will evolve over time, and this Policy will change to reflect that evolution. See data at the end of this document for the date of the last revision to the Policy. We encourage you to review this Policy periodically to stay informed about our practices.",
  str_privacy_policy_sharing_of_information_1:
    "We may share information about you with others as follows:",
  str_privacy_policy_sharing_of_information_2:
    "<1>With service providers that perform work for us so that they can perform such work;\n</1><1>When you use interactive areas of our Services, like our blog or other online forums, certain information you choose to share may be displayed publicly, such as your username, actions you take, and any content you post;\n</1><1>In response to a request for information if we believe disclosure is in accordance with, or required by, an applicable law, regulation, or legal process;\n</1><1>If we believe your actions are inconsistent with our Agreements or policies, or to protect the rights, property, and safety of <0>Oceans Rare</0> or others;\n</1><1>In connection with, or during negotiations of, any merger, sale of Company assets, financing or acquisition of all or a portion of our business by another company;\n</1><1>Between and among <0>Oceans Rare</0> and any current and future parents, affiliates, subsidiaries and other companies under common control and ownership; or,\n</1><1>With your consent or at your direction.</1>",
  str_privacy_policy_use_of_information_1:
    "We may use information about you for various purposes, including to:",
  str_privacy_policy_use_of_information_2:
    "<1>Provide, maintain, deliver, and improve our Services;\n</1><1>Develop new products and services;\n</1><1>Personalize your experience;\n</1><1>Respond to your requests for information;\n</1><1>Send you technical notices as well as support and administrative messages;\n</1><1>Subject to applicable legal obligations, communicate with you about products, services, promotions, events, and other news and information we think will be of interest to you;\n</1><1>Monitor and analyze trends, usage, and activities in connection with our Services;\n</1><1>Detect, investigate, and prevent suspected fraudulent transactions and other illegal activities, and protect the rights and property of <0>Oceans Rare</0> and others;\n</1><1>Investigate good-faith, alleged violations of our Agreements;\n</1><1>Secure our Services and Customers;\n</1><1>Link or combine information we collect from or about you;\n</1><1>Carry out any other purpose for which the information was collected; and,\n</1><1>Fulfill other purposes with your consent or at your direction.</1>",
  str_privacy_policy_use_of_information_3:
    "For the purposes discussed in this Privacy Policy, we may combine the information that we collect through the Services with information that we receive from other sources, both online and offline, and use such combined information in accordance with this Privacy Policy.",
  str_prohibited: "Prohibited Activities",
  str_put_in: "From",
  str_put_out: "To",
  str_required: "Required Fields",
  str_revoke: "Revoke",
  str_revoke_NFT: "Revoke NFT",
  str_revoke_failed: "Revoke failed",
  str_risk: "Risks",
  str_sale: "Sale",
  str_search_collection_placeholder: "Search by collection name",
  str_search_nft_placeholder: "Search by NFT name",
  str_search_placeholder: "Search by NFT name, collection name",
  str_security_of_data: "Security Of Data",
  str_sell: "Sell",
  str_sell_NFT: "Sell NFT",
  str_sell_NFT_apply_success: "The NFT is on the market",
  str_selling_nft: "NFTs for Sale",
  str_send_from: "From",
  str_send_to: "To",
  str_set_approve: "Set Approve for All",
  str_sharing_of_information: "Sharing of Information",
  str_state: "State",
  str_status: "Status",
  str_successfully_buy: "Buy successfully",
  str_switch_network: "Switch Network",
  str_term_of_use_applicable_law_and_jurisdiction_1:
    "These Terms shall be governed and construed in accordance with the laws of Nebraska, United States, without regard to its conflict of law provisions.\n\nOur failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.",
  str_term_of_use_contact_1:
    "We welcome all questions, concerns, and feedback you might have about these Terms. If you have suggestions for us, please contact our customer success team.",
  str_term_of_use_limitation_of_liability_1:
    "<0>Oceans Rare</0> shall not be liable for any lost profits, special, punitive, indirect, or incidental damages arising in connection with or related to these Terms, us, or our Services, even if we have been advised of the possibility of such damages. our total liability relating to, arising out of, or in any way connected with our terms and conditions, us, or our services shall not exceed one dollar ($1). the foregoing disclaimer of certain damages and limitation of liability will apply to the fullest extent permitted by applicable law. some jurisdictions do not allow the exclusion or limitation of certain damages, so some or all of the above exclusions and limitations may not apply to you. notwithstanding anything to the contrary in these terms and conditions, the liability of the <0>Oceans Rare</0> parties shall be limited to the maximum extent permitted by applicable law.",
  str_term_of_use_modifications_1:
    "We may revise these Terms from time to time to better reflect:\n\n<1>improvements or enhancements made to our Software\n</1><1>new regulatory requirements, or\n</1><1>changes in legislation.</1>\nIf we make revises, we will notify you by revising the Last Updated date at the beginning of this Policy accordingly. Revise are binding on users of the Site and will take effect immediately upon posting. As a user, you agree to be bound by any changes, variations, or modifications to this Policy and your continued use of the Site shall constitute acceptance of any such changes, revisions, variations, or modifications. By continuing to use the Site, you also acknowledge and agree that we have provided you with sufficient notice of such changes.  Our Terms of Use Agreement and Cookies Policy also apply to your access and use of the Site.",
  str_term_of_use_prohibited_activities_1:
    "<2>We strive to maintain great Services. To that end, <0>Oceans Rare</0> requires you to respect these limitations, and we may terminate your account if you don’t follow them.</2>",
  str_term_of_use_prohibited_activities_10:
    "<2>Don’t use our Services in any manner that permits the creation, support, distribution, or other transfer of any viruses or any other computer code, files or programs that interrupt, impair, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network.</2>",
  str_term_of_use_prohibited_activities_11:
    "<2>Don’t interfere with the proper functioning of any software, hardware, or equipment on our Services.<2>",
  str_term_of_use_prohibited_activities_12:
    "<2>Don’t engage in any conduct that inhibits or disupts anyone else’s use or enjoyment of our Services, or which we determine may harm we or our users.</2>",
  str_term_of_use_prohibited_activities_13:
    "<2>Don’t instigate or encourage others to commit illegal activities or cause injury to any person or property damage.</2>",
  str_term_of_use_prohibited_activities_14:
    "<2>Don’t encourages or otherwise engage in any conduct that would constitute a criminal offense or that gives rise to civil liability.</2>",
  str_term_of_use_prohibited_activities_15:
    "<2>Don’t use our Services contrary to our policies, any Agreement you have with us, or our rules, policies, or other guidelines applicable to the Services.</2>",
  str_term_of_use_prohibited_activities_16:
    "<2>Don’t violate the ITAR Act §22 USC 2778 and its amendments, including but not limited to the import or use of the Services in Afghanistan, Belarus, Burma, the Central African Republic, China, the Ivory Coast, Cuba, Cyprus, Democratic Republic of Congo, Eritrea, Haiti, Iran, Iraq, Kyrgyzstan, Lebanon, Liberia, Libya, North Korea, Russia, Somalia, Sri Lanka, Sudan, Syria, Venezuela, Vietnam, or Zimbabwe; and.</2>",
  str_term_of_use_prohibited_activities_17:
    "<2>Please don’t use our Services if you’re under sixteen years old or the age of majority in your jurisdiction.</2>",
  str_term_of_use_prohibited_activities_2:
    "<2>Don’t use our Services in a manner that is unlawful, threatening, abusive, hateful, defamatory, slanderous, libelous, deceptive, fraudulent, promoting of violence, invasive of another’s privacy, tortious, indecent or obscene.</2>",
  str_term_of_use_prohibited_activities_3:
    "<2>Don’t use our Services in a manner that violates or infringes the rights of others, including without limitation patent, trademark, trade secret, copyright, publicity or other proprietary rights.</2>",
  str_term_of_use_prohibited_activities_4:
    "<2>Don’t use our Services in a manner that involves uploading, posting, emailing, transmitting or otherwise making available any data that you not have the right to make available under any law or under contractual or fiduciary relationships (such as insider information, proprietary and confidential information learned or disclosed as part of employment relationships or under non-disclosure agreements, etc.</2>",
  str_term_of_use_prohibited_activities_5:
    "<2>Don’t use our Services to do anything that victimizes, harasses, stalks, degrades, attacks or intimidates an individual or group of individuals on any basis, including but not limited to religion, gender, sexual orientation, race, ethnicity, age or disability.</2>",
  str_term_of_use_prohibited_activities_6:
    "<2>Don’t use our Services for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</2>",
  str_term_of_use_prohibited_activities_7:
    "<2>Don’t use our Services to promote sexually explicit or pornographic material.</2>",
  str_term_of_use_prohibited_activities_8:
    "<2>Don’t impersonate any person, business or entity (including but not limited to a DIVER official), or in any way falsely state or misrepresent your affiliation with a person or entity.</2>",
  str_term_of_use_prohibited_activities_9:
    "<2>Don’t use our Services in any manner that involves forging headers or otherwise manipulating identifiers in order to disguise the origin of any content transmitted or shared through the Services.<2>",
  str_term_of_use_risks_start:
    "Please note the following risks in accessing or using Oceans Rare.",
  str_term_of_use_risks_end:
    "You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that Oceans Rare does not give advice or recommendations regarding DIVER, including the suitability and appropriateness of, and investment strategies for, DIVER. You agree and understand that you access and use Oceans Rare at your own risk.\n however, this brief statement does not disclose all of the risks associated with DIVER and other digital assets. You agree and understand that Oceans Rare will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using DIVER, however caused.",
  str_term_of_use_risks_1:
    "The price and liquidity of blockchain assets, including DIVER, are extremely volatile and may be subject to large fluctuations.",
  str_term_of_use_risks_2:
    "Fluctuations in the price of other digital assets could materially and adversely affect DIVER, which may also be subject to significant price volatility.",
  str_term_of_use_risks_3:
    "Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of DIVER.",
  str_term_of_use_risks_4:
    "DIVER are not legal tender and are not backed by the government.",
  str_term_of_use_risks_5:
    "Transactions in DIVER may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable.",
  str_term_of_use_risks_6:
    "Some transactions in DIVER shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the transaction.",
  str_term_of_use_risks_7:
    "The value of DIVER may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for DIVER, which may result in the potential for permanent and total loss of value of a particular DIVER should the market for that Nifty disappear.",
  str_term_of_use_risks_8:
    "The nature of DIVER may lead to an increased risk of fraud or cyber attack, and may mean that technological difficulties experienced by <0>Oceans Rare</0> may prevent the access to or use of your Digital Assets.",
  str_terms_of_service: "Terms of Use",
  str_terms_of_service_about_diver_market_1:
    "<0>Oceans Rare</0> is a distributed application, functioning as a marketplace, that runs on the DIVER time network, using specially-developed smart contracts that provide an immutable ledger of all transactions that occur on <0>Oceans Rare</0> (Smart Contracts). Using these Smart Contracts, users can create, buy, transfer and trade unique digital asset s in the form of a non-fungible token (NFT).\n\n<0>Oceans Rare</0> is not involved in the actual transaction between buyers and sellers on the marketplace. We are an administrative platform that facilitates transactions between a buyer and a seller. The contract for the sale is directly between buyer and seller.\n\nWe have no control over, and do not guarantee the existence, quality, safety or legality of, items advertised; the ability of sellers to sell items; the ability of buyers to pay for items; or that a buyer or seller will actually complete a transaction.\n\nIn this respect, you acknowledge and agree that <0>Oceans Rare</0> is a non-custodial software provider that has developed this distributed and decentralized environment where you can autonomously and directly access the App without any involvement whatsoever by <0>Oceans Rare</0> or any third party.",
  str_terms_of_service_accessing_and_using_the_service_1:
    "<0>Connect your Wallet\n</0>To create an Account and enjoy full functionality of our Services you must connect your third-party DIVER-compatible digital wallet on a supported bridge extension, such as DIVER Wallet, Connect to wallet, and others supported on the Site. By connecting your digital wallet to the Site, you expressly represent that you are the lawful and rightful owner of the digital wallet. You are required to authenticate ownership of your digital wallet before you can perform transactions through the Services by cryptographically verifying, through your third-party bridge extension, your possession of the digital wallet’s associated passphrase. Your digital wallet transactions are broadcasted to the DIVER blockchain through the applicable third-party bridge extension, which facilitates transactions on your behalf. By using your digital wallet in connection with the Services, you agree that you are using that wallet lawfully and under the terms and conditions of the applicable third party-wallet provider. Your digital wallet and all assets held therein are your personal property, and not the property of the Company.  The Company has no responsibility or technical ability to manage, modify, secure, or perform transactions on behalf of your digital wallet.",
  str_terms_of_service_account_1:
    "You must create an account with <0>Oceans Rare</0> to use the Services or the App. We will ask you certain information for the account to be created. By creating an account, you agree to provide accurate, current and complete account information about yourself, and to maintain and promptly update, as necessary, your account information. For further information on how we use that information, please see our Privacy Policy.\n\nYou understand that your <0>Oceans Rare</0> account is limited solely to you. You agree that you will not grant access, in any way, to any person without our prior written permission.\nYou understand that you are solely responsible for maintaining the security of your account and control over any usernames, private keys, or any other authentications or codes that you use to access the Services. Any unauthorised access to your account could result in the loss or theft of Tokens and/or funds in any linked accounts. You understand and agree that you will not hold us responsible for managing and maintaining the security of your account and your DIVER Wallet, wallet (or other DIVER wallets and accounts). You further understand and agree that we are not responsible (and you will not hold us responsible) for any unauthorised access to, or use of, your account or your DIVER wallet (or other DIVER wallets and accounts).",
  str_terms_of_service_general_1:
    "<1>Introduction</1>Oceans Rare Service, Inc. (Foundation, we, us, our) provides its marketplace and services (described below) to you (you or User) through its website, platform, and marketplace located at https://diver-rare.com (the Platform), subject to the following Terms of Service (as amended from time to time, the Terms). By signing up for an account on the Platform or otherwise using or accessing the Platform, you acknowledge that you have read and agree to these Terms. The Privacy Policy and all such additional terms, guidelines, and rules as set forth on the Platform are hereby incorporated by reference into these Terms and expressly agreed to and acknowledged by the User.",
  str_terms_of_service_general_2:
    "<1>The Company Is a Non-Custodial Service Provider</1>The Company does not ever have possession, custody, or control of your digital wallet, NFTs or other assets held in your digital wallet, nor does it stand as an intermediary, processor, escrow agent, or direct beneficiary of any transactions conducted by you involving other users of the Service or Protocol. The Company is not a party to any agreement or transactions involving you and other users or assets. Your technical, legal, property, and economic interests, relationships, or rights as between you and other users of the Service are facilitated exclusively by the non-custodial smart contracts , or your own voluntary actions, which the Company does not control. The Company is not a wallet provider, exchange, broker, financial institution, money services business, trust, or creditor.",
  str_terms_of_service_general_3:
    "<1>Legal Capacity To Use the Services</1>You expressly represent and warrant that you have the right, authority, and capacity to enter into this agreement on behalf of yourself and any third-party individual or organization for which you are acting as an authorized agent. You expressly represent and warrant that you are over the age of majority in your jurisdiction and at least over the age of thirteen (13). If you are under eighteen (18) years old, you may only use the Services through a parent or guardian’s Account and upon their separate agreement to these Terms. It is prohibited to use our Service if you are under thirteen (13) years old. Parents and guardians should be aware that some of the third-party Creator content provided through the Service may be not safe for work (NSFW).",
  str_terms_of_service_intellectual_property_1:
    "<0>Oceans Rare’s IP</0>\nThe Marketplace and its functionalities, the Website and any content generated by Oceans Rare are Oceans Rare’s sole intellectual property and are protected by all intellectual property rights in force. Access or use of the Services does not grant any right to the Users on these contents.\nCollectors acknowledge and agree that except as otherwise expressly stated, use of the Services orpurchase of an NFT does not confer any ownership or other rights in the content, code, data or othermaterials on the Website that may be accessed through it nor over the Services.Any use, reproduction or representation in any form of the Marketplace or the Website without theexpress written permission of Oceans Rare is prohibited.",
  str_terms_of_service_intellectual_property_2:
    "<0>Creator’s IP</0>\nCollectors are the sole owners of the token associated with the NFT they purchase. It is however recalled that an NFT purchased on the Marketplace may be composed of various elements including but not limited to  i and ii\n\n<2>a token stored on the DIVER blockchain</2><2>a File, which may refer to various intellectual property rights.</2>\nTherefore, Collectors acknowledge and agree that except as otherwise expressly stated or granted by Creators, use of the Services or purchase of an NFT does not confer any ownership, intellectual property right or any other right of any kind whatsoever on File Related Rights.",
  str_terms_of_service_pricing_and_fees_1:
    "<2>If you elect to purchase, trade, or create art on the App, or with or from other users via the App, any financial transactions that you engage in will be conducted solely through the DIVER network via DIVER Wallet (or other DIVER-compatible wallets and browsers). We have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. With that in mind, we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the App, or using the Smart Contracts, or any other transactions that you conduct via the DIVER Network or DIVER.</2><2>We do not guarantee the accuracy or adequacy of the price of any item sold or offered for sale on or off <0>Oceans Rare</0>. Fluctuations in the price of digital assets could materially and adversely affect the item, which may also be subject to significant price volatility.</2><2><0>Oceans Rare</0> charges a fee based on the total sale price that is transferred by the smart contract without the involvement of the seller and buyer at any point during the transaction. Further fees may include royalties to the creator of the item or gas fees. By using <0>Oceans Rare</0>, the User hereby acknowledges, consents to, and accepts all automated fees, commissions, and royalties for the sale of Items.</2>",
  str_terms_of_service_privacy_1:
    "Our Privacy Policy governs the collection and use of your personal information. Please review those provisions as well.",
  str_terms_of_service_third_party_services_1:
    "Our Services may allow you to access, use, or interact with third-party websites, apps, content, and other products and services. When you use third-party services, their terms and privacy policies govern your use of those services.",
  str_third_party_service: "Third-party services",
  str_token_id: "Token ID",
  str_token_standard: "Token Standard",
  str_trading_history: "Trading History",
  str_transaction_fee_amount: "Market Fee: {{amount}}",
  str_transaction_fee_amount_tooltip:
    "After a successful sale of the NFT, the system will automatically deduct 10% from your selling price as a market fee.",
  str_transfer: "Transfer",
  str_use_of_information: "Use of Information",
  str_wallet_address: "Wallet Address",
  str_wallet_address_placeholder: "Please enter wallet address",
  str_wallet_connect: "Connect Wallet",
  str_wallet_connecting: "Connecting Wallet",
  str_withdraw_funds: "Withdraw Funds",
  str_withdraw_funds_success: "Withdraw Funds Successfully",
  str_withdrawal: "Withdrawal",
  str_only_image_file: "Only image files (JPG, PNG, GIF) can be uploaded.",
  wallet_connecting: "Connecting Wallet",
  wallet_connecting_text: "I have a DIVER Wallet",
  wallet_connecting_download_text: "Click here if you are a first-time visitor",
  wallet_connecting_download_web: "Add the DIVER Wallet extension",
  wallet_connecting_download_rwd: "Download DIVER Wallet",
  str_mint_fee: "※Each time you Mint an NFT, Oceans Rare will charge you 2,000,000 DIV as the Minting fee.",
  str_error_file_type: "Please upload a file with the correct format",
  str_price_value_too_low: "Price must be greater than or equal to 10",
  str_price_value_too_high: "Price must be less than or equal to 10,000,000,000",
  str_transfer_success: "Transfer Application Successfully",
  str_transfer_success_message: "When Transaction finish, the NFT will be display in account",
  str_go_to_profile: "Back to Profile",
  str_failed_transfer: "Transfer failed",
  str_failed_burn: "Burn failed",
}

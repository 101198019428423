import { Box, Dialog, SvgIcon, useTheme } from "@mui/material"
import React, { ReactNode } from "react"
import { DialogTypeEnum } from "@/core/constant/DialogTypeEnum"
import CloseIcon from "@mui/icons-material/Close"
import { CommonButton } from "@/components/common/CommonButton"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoIcon from "@mui/icons-material/Info"
import CancelIcon from "@mui/icons-material/Cancel"
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded"

export type CommonDialogProps = {
  children?: ReactNode
  type?: DialogTypeEnum
  open: boolean
  close?: () => void
  title?: string
  content?: string | JSX.Element
  buttonLeft?: {
    text: string
    action?: () => void
    loading?: boolean
  }
  buttonRight?: {
    text: string
    action?: ([key]?: any) => void
    loading?: boolean
  }
  hideCloseIcon?: boolean
}

export const CommonDialog: React.FunctionComponent<CommonDialogProps> = ({
  open,
  close = () => undefined,
  type,
  title,
  content,
  buttonLeft,
  buttonRight,
  children,
  hideCloseIcon,
}) => {
  const { t } = useTranslation()
  const { palette, breakpoints } = useTheme()

  const iconDisplay = {
    [DialogTypeEnum.INFO]: InfoIcon,
    [DialogTypeEnum.SUCCESS]: CheckCircleIcon,
    [DialogTypeEnum.WARNING]: ReportProblemRoundedIcon,
    [DialogTypeEnum.ERROR]: CancelIcon,
    [DialogTypeEnum.DELETE]: ReportProblemRoundedIcon,
  }

  const iconColor = {
    [DialogTypeEnum.INFO]: "primary.main",
    [DialogTypeEnum.SUCCESS]: "primary.main",
    [DialogTypeEnum.WARNING]: "warning.main",
    [DialogTypeEnum.ERROR]: "error.dark",
    [DialogTypeEnum.DELETE]: "warning.main",
  }
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
          [breakpoints.down("md")]: {
            margin: 4,
          },
        },
      }}
    >
      {!hideCloseIcon && (
        <Box
          sx={{
            color: palette.text.secondary,
            "&:hover": { color: palette.error.main },
          }}
          className="absolute right-2 top-1 cursor-pointer"
          onClick={() => close()}
        >
          <CloseIcon
            sx={{
              fontSize: 28,
              [breakpoints.down("md")]: {
                fontSize: 22,
              },
            }}
          />
        </Box>
      )}
      <div className="w-auto p-6 pt-8 text-gray-800 sm:min-w-[432px] sm:p-8 md:p-12">
        {children || (
          <>
            {type && type !== DialogTypeEnum.FORM && (
              <div className="text-center">
                <SvgIcon
                  component={iconDisplay[type]}
                  sx={{ width: 80, height: 80, color: iconColor[type] }}
                />
              </div>
            )}
            <div className="text-h3 mb-4 mt-4 text-center font-bold">
              {title}
            </div>
            <div className="text-h5 whitespace-break-spaces text-center font-medium">
              {content}
            </div>
            {(buttonLeft || buttonRight) && (
              <div className="mt-12 flex justify-center px-4">
                {buttonLeft && (
                  <CommonButton
                    variant="outlined"
                    sx={{
                      marginRight: 1,
                      minHeight: 50,
                      paddingX: 8,
                      width: "50%",
                    }}
                    onClick={() => {
                      buttonLeft.action ? buttonLeft.action() : close()
                    }}
                  >
                    {buttonLeft.text || t("str_cancel")}
                  </CommonButton>
                )}
                {buttonRight && (
                  <CommonButton
                    variant="contained"
                    sx={{
                      marginLeft: 1,
                      minHeight: 50,
                      paddingX: 8,
                      width: "50%",
                    }}
                    onClick={() => {
                      buttonRight.action ? buttonRight.action() : close()
                    }}
                  >
                    {buttonRight.text || t("str_confirm")}
                  </CommonButton>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Dialog>
  )
}

import * as React from "react"
import { ReactElement, Suspense } from "react"
import { useRoutes } from "react-router-dom"

const MainLayout = React.lazy(() => import("../components/layout/MainLayout"))
const LoginLayout = React.lazy(() => import("../components/layout/LoginLayout"))
const CollectionPage = React.lazy(() => import("./collection"))
const AssetsPage = React.lazy(() => import("./assets"))
const ProfilePage = React.lazy(() => import("./profile"))
const OverviewPage = React.lazy(() => import("./overview"))
const NotFoundPage = React.lazy(() => import("./404"))
const CreateNFTPage = React.lazy(() => import("./create_nft"))
const CreateCollectionPage = React.lazy(() => import("./create_collection"))
const PrivacyPolicyPage = React.lazy(() => import("./privacy_policy"))
const TermsOfUsePage = React.lazy(() => import("./terms_of_use"))

export function App(): ReactElement | null {
  return useRoutes([
    {
      element: (
        <Suspense>
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense>
              <LoginLayout />
            </Suspense>
          ),
          children: [
            {
              path: "/create_nft",
              element: (
                <Suspense>
                  <CreateNFTPage />
                </Suspense>
              ),
            },
            {
              path: "/create_collection",
              element: (
                <Suspense>
                  <CreateCollectionPage />
                </Suspense>
              ),
            },
            {
              path: "/profile",
              element: (
                <Suspense>
                  <ProfilePage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/",
          element: (
            <Suspense>
              <OverviewPage />
            </Suspense>
          ),
        },
        {
          path: "/collection",
          element: (
            <Suspense>
              <CollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/collection/:collectionId",
          element: (
            <Suspense>
              <CollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/nft",
          element: (
            <Suspense>
              <AssetsPage />
            </Suspense>
          ),
        },
        {
          path: "/nft/:nftId/:tokenId",
          element: (
            <Suspense>
              <AssetsPage />
            </Suspense>
          ),
        },
        {
          path: "/terms_of_use",
          element: (
            <Suspense>
              <TermsOfUsePage />
            </Suspense>
          ),
        },
        {
          path: "/privacy_policy",
          element: (
            <Suspense>
              <PrivacyPolicyPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
  ])
}

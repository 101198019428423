import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./pages"
import "./styles/global.css"
import "swiper/css"
import "swiper/css/pagination"
import { BrowserRouter } from "react-router-dom"
import { Layout } from "./components/layout/Layout"

function resizeFullHeight() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

resizeFullHeight()
window.addEventListener("resize", () => {
  resizeFullHeight()
})

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <Layout>
      <App />
    </Layout>
  </BrowserRouter>
)
